import axios from 'axios'

export function addNotificaciones (notificacion) {
  notificacion.email = localStorage.getItem('user')
  return axios.post('/notificaciones', notificacion)
  .then(response => {
    return response.data
  })
}

export function getAllNotificaciones () {
  return axios.get('/notificaciones')
  .then(response => {
    return response.data
  })
}

export function getAllNotificacionesByEmail () {
  var email = localStorage.getItem('user')
  return axios.get('/notificacion?email=' + email)
  .then(response => {
      return response.data
    })
}

export function getNotificacionesByEmailPendientes () {
  var email = localStorage.getItem('user')
  return axios.get('/notificacion/pendientes?email=' + email)
  .then(response => {
      return response.data
    })
}

export function updateNotificacion (id) {
  return axios.put('/notificaciones/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  addNotificaciones,
  getAllNotificaciones,
  getAllNotificacionesByEmail,
  getNotificacionesByEmailPendientes,
  updateNotificacion,
}
