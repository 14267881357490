<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <base-material-card
      color="indigo"
      icon=" mdi-bell"
      title="Listado de Notificaciones"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="notificaciones"
        class="elevation-1"
      >
        <template v-slot:item.fecha_envio.date="{ item }">
          <span>{{ formatDate(item.fecha_envio.date) }}</span>
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-icon
            v-if="item.visto"
            small
            class="mr-2"
            color="success"
            disabled="disabled"
          >
            mdi-check-outline
          </v-icon>
          <v-icon
            v-else
            small
            class="mr-2"
            color="success"
            @click="editItem(item)"
          >
            mdi-check-outline
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Recargar
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import NotificacionesApi from '@/services/api/Notificaciones'
  import EventBus from '../../bus'

  export default {
    name: 'Notificaciones',

    data () {
      return {
        valid: true,
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        notificaciones: [],
        editedIndex: -1,
        lazy: false,
        headers: [
          {
            text: 'Notificador',
            align: 'left',
            sortable: false,
            value: 'notificador',
          },
          {
            text: 'Mensaje',
            align: 'left',
            sortable: false,
            value: 'mensaje',
          },
          {
            text: 'Fecha Envío',
            align: 'left',
            sortable: false,
            value: 'fecha_envio.date',
          },
          {
            text: 'Visto',
            align: 'left',
            sortable: false,
            value: 'visto',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        editedItem: {
          id: '',
          notificador: '',
          mensaje: '',
          fecha_envio: '',
          visto: '',
        },
        defaultItem: {
          id: '',
          notificador: '',
          mensaje: '',
          fecha_envio: '',
          visto: '',
        },
      }
    },
    created () {
      this.initialize()
    },
    methods: {
      formatDate (value) {
        var month = ((new Date(value).getMonth() + 1) < 10) ? '0' + (new Date(value).getMonth() + 1) : (new Date(value).getMonth() + 1)
        var date = (new Date(value).getDate() < 10) ? '0' + new Date(value).getDate() : new Date(value).getDate()
        return new Date(value).getFullYear() + '-' + month + '-' + date
      },
      fetchNotificaciones () {
        // NotificacionesApi.getAllNotificacionesByEmail()
        //   .then(notificaciones => {
        //     this.notificaciones = notificaciones
        //   })
        //   .catch(error => console.log(error))
        this.$store.dispatch('getAllNotificacion')
          .then(notificaciones => {
            this.notificaciones = notificaciones
          })
          .catch(error => console.log(error))
      },
      initialize () {
        this.fetchNotificaciones()
        EventBus.$on('update-table-notify', () => {
          this.fetchNotificaciones()
        })
      },

      editItem (item) {
        this.editedIndex = this.notificaciones.indexOf(item)
        this.editedItem = Object.assign({}, item)
        if (confirm('Realmente desea actualizar este registro?')) {
          const indexItem = this.editedIndex
          const updNotificacion = this.editedItem
          NotificacionesApi.updateNotificacion(item.id)
            .then(response => {
              Object.assign(this.notificaciones[indexItem], updNotificacion)
              EventBus.$emit('update-fetch_notify')
              this.fetchNotificaciones()
              this.text = 'Registro modificado correctamente'
              this.snackbar = true
              this.colorSnak = 'success'
              this.editedIndex = -1
            })
            .catch(error => {
              console.log(error)
              this.text = 'El registro no puede ser modificado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
              this.editedIndex = -1
            })
        }
      },
    },
  }
</script>
